<template>
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        />
      </div>
    </div>
    <div class="content-body content-body--senior-call">
      <div class="row">
        <div class="col-12">
          <div class="card card--table">
            <CompleteTable
              :sorted-field="sortedField"
              :data="users"
              :sort-mapping="sortMapping"
              :title="'archived applicants'"
              :total="usersTotal"
              :export-path="''"
              :fetch-path="'applicants/applicantsList'"
              :filter-path="'applicants/filterApplicantsList'"
              :actions="true"
              @selectedItems="saveSelectedItems"
            >
              <template v-slot="{field}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item @click="$store.dispatch('modals/toggleUserTraces', true), traceUser = field">
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View traces
                      </b-dropdown-item>
                      <b-dropdown-item @click="openCanva(true, field)">
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View form data
                      </b-dropdown-item>
                      <b-dropdown-item @click="openCanva(false, field)">
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View form evaluation
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </template>
              <template #customfilters>
                <div class="mb-1">
                  <label
                    for="name"
                    class="form-label"
                  >Name</label>
                  <input
                    type="text"
                    class="form-control"
                    name="name"
                    @change="saveFilter($event.target.value, 'name')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="name"
                    class="form-label"
                  >Universities</label>
                  <v-select
                    multiple
                    label="name"
                    :options="institutions"
                    :get-option-key="option => option.name"
                    @input="saveFilter($event, 'hostFromArray')"
                    @search="onSearch({ name: $event, typeName: 'University' }, 'institutions/filter')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="name"
                    class="form-label"
                  >Areas</label>
                  <v-select
                    multiple
                    label="name"
                    :options="areas"
                    :get-option-key="option => option.name"
                    @input="saveFilter($event, 'areaFromArray')"
                    @search="onSearch({ name: $event }, 'areas/filter')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="name"
                    class="form-label"
                  >Call</label>
                  <v-select
                    multiple
                    label="title"
                    :options="convos"
                    :get-option-key="option => option.id"
                    @input="saveFilter($event, 'enterCalls')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="name"
                    class="form-label"
                  >Submitted</label>
                  <v-select
                    label="label"
                    :options="[
                      {label: 'Yes', name: 'Submitted', value: true},
                      {label: 'No', name: 'Unsubmitted', value: false},
                    ]"
                    :get-option-key="option => option.value"
                    @input="saveFilter($event, 'submitted')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >ERC code</label>
                  <v-select
                    label="name"
                    :options="ercs"
                    :get-option-key="option => option.id"
                    @input="saveFilter($event, 'erc_main')"
                    @search="onSearch({ name: $event }, 'erc/filter')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="name"
                    class="form-label"
                  >Blocket for next year</label>
                  <v-select
                    label="label"
                    :options="[
                      {label: 'Yes', name: 'Blocked next year', value: true},
                      {label: 'No', name: 'Unblocked next year', value: false},
                    ]"
                    :get-option-key="option => option.name"
                    @input="saveFilter($event, 'blocked')"
                  />
                </div>
                <!-- <div class="mb-1">
                  <label
                    for="name"
                    class="form-label"
                  >With grade</label>
                  <v-select
                    label="label"
                    :options="[
                      {label: 'Yes', name: 'With grade', value: true},
                      {label: 'No', name: 'Without grade', value: false},
                    ]"
                    :get-option-key="option => option.name"
                    @input="saveFilter($event, 'grade')"
                  />
                </div> -->
              </template>
              <template #info-sheet-item>
                <div class="offcanvas-body offcanvas-body--view">
                  <h4 class="mb-2">
                    <strong class="text-success">{{ selectedItems.length }}</strong> selected applicants
                  </h4>
                  <ul
                    id="basic-list-group"
                    class="list-group"
                  >
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="tableAction-Block"
                              v-model="actions.block_unblock"
                              type="radio"
                              class="form-check-input"
                              name="actions"
                              value="1"
                            >
                            <label
                              class="form-check-label"
                              for="tableAction-Block"
                            >Block applicants for next year</label>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="tableAction-Unblock"
                              v-model="actions.block_unblock"
                              type="radio"
                              class="form-check-input"
                              name="actions"
                              value="2"
                            >
                            <label
                              class="form-check-label"
                              for="tableAction-Unblock"
                            >Unblock users for next year</label>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="offcanvas-footer mt-auto">
                    <a
                      href="#"
                      title="apply actions"
                      class="btn btn-primary mb-1 d-grid w-100"
                      @click="applyActions"
                    >Apply actions</a>
                  </div>
                </div>
              </template>
            </CompleteTable>
            <ManageUserTraces :user="traceUser" />
            <ShowFormEvaluation :selected="selectedItem" />
            <ShowFormData :selected="selectedItem" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import ManageUserTraces from '../../partials/offcanvas/ManageUserTraces.vue'
import CompleteTable from '../../partials/components/CompleteTable.vue'
import ShowFormEvaluation from '../academy-call/components/ShowFormEvaluation.vue'
import ShowFormData from '../academy-call/components/ShowFormData.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    ManageUserTraces,
    ShowFormEvaluation,
    ShowFormData,
  },
  data() {
    return {
      traceUser: {},
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Email',
          checked: true,
          order: 2,
        },
        {
          name: 'University',
          checked: true,
          order: 3,
        },
        {
          name: 'Area',
          checked: true,
          order: 4,
        },
        {
          name: 'ERC',
          checked: true,
          order: 5,
        },
        {
          name: 'Call',
          checked: true,
          order: 6,
        },
        {
          name: 'Submitted',
          checked: true,
          order: 7,
        },
        {
          name: 'Blocked',
          checked: true,
          order: 8,
        },
        {
          name: 'Grade',
          checked: true,
          order: 9,
        },
      ],
      sortMapping: {
        Name: 'name',
        Email: 'email',
        University: 'university.code',
        Area: 'area.code',
        ERC: 'ercs',
        Call: 'convo_name',
        Submitted: 'submitted',
        Blocked: 'blocked',
        Grade: 'grade',
      },
      title: 'Applicants',
      selectedItems: [],
      actions: {},
      selectedItem: {},
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      users: 'applicants/applicantsList',
      usersTotal: 'applicants/applicantsListTotal',
      filters: 'filters/filters',
      institutions: 'institutions/institutions',
      areas: 'areas/areas',
      ercs: 'erc/items',
      convos: 'convo/convos',
      convo: 'convo/convo',
    }),
  },
  async mounted() {
    await this.$store.dispatch('applicants/filterApplicantsList', this.filters)
    await this.$store.dispatch('modals/fetchUserFields', 'applicants_list')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'applicants_list',
      })
    }
    await this.$store.dispatch('convo/fetchAll', 'academy')

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async openCanva(formData, field) {
      if (!this.convo || (this.convo && this.convo.id !== field.convo.id)) {
        await this.$store.dispatch('convo/fetchSpecific', field.convo.id)
      }

      this.selectedItem = field

      if (formData) {
        this.$store.dispatch('modals/toggleFormData', true)
      } else {
        this.$store.dispatch('modals/toggleFormEvaluation', true)
      }
    },
    async saveFilter(value, field) {
      if (field === 'enterCalls') {
        value.map(e => {
          e.name = e.title
          return e
        })
      }
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
    saveSelectedItems(items) {
      this.selectedItems = items
    },
    getActionsString() {
      let str = ''
      const year = '2024'

      if (this.actions.block_users) {
        str += `<p class="text-success">Block the selected applicants to year ${year}</p>`
      }

      if (this.actions.unblock_users) {
        str += `<p class="text-success">Unblock the selected applicants to year ${year}</p>`
      }

      return str
    },
    async applyActions() {
      this.$store.dispatch('modals/toggleInfoItemSheet', false)
      if (this.selectedItems.length > 0) {
        Vue.swal({
          title: 'Perform the following actions on the selected applicants?',
          html: this.getActionsString(),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes, apply!',
        }).then(result => {
          if (result.isConfirmed) {
            const itemIds = this.selectedItems.map(el => el.id)
            this.$store.dispatch('applicants/blockUnblockApplicant', { actions: this.actions, itemIds }).then(async e => {
              await this.$store.dispatch('applicants/fetchApplicantsList')
              if (e) {
                Vue.swal('Actions applied successfully!', '', 'success')
              } else {
                Vue.swal('Actions failed!', '', 'error')
              }
            })
          }
        })
      } else {
        this.$toastr.error('', 'No items selected!')
      }
    },
  },
}
</script>
